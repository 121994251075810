import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import {MathJax} from "better-react-mathjax";
// import MathJax from 'react-mathjax-preview'
// import "react-mathjax";

import Config from "../../helpers/Config";
import Logger from "../../helpers/Logger";

import "./Formula.css";


export default class Formula extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			text: props.text,
		};
	}
	
	render() {
		
		const logName = 'Formula.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			<div className={'Formula'}>
				{this.props.showEditor &&
					<div className={'editor'}>
						<input
							className={'form-control'}
							type={'text'}
							onChange={(event) => {
								let text = event.target.value;
								this.setState((prevState) => {
									return {
										text: text,
									}
								});
								if (this.props.onChange) {
									this.props.onChange(text, event);
								}
							}}
							value={this.state.text}
							disabled={this.props.disabled}
							onPaste={(evt) => {
								if (!Boolean(this.props.allowPaste)) {
									evt.preventDefault()									
								}
							}}
						/>
						<small className={'form-text text-muted formula-help'}>
							{i18next.t("Enter AsciiMath code")} (<a href={Config.asciiMathSyntaxUrl} target={'_blank'}>{i18next.t("syntax help")}</a>)
						</small>
					</div>
				}
				{(this.state.text) &&
					<>
						<MathJax dynamic>
							{"`" + this.state.text + "`"}
						</MathJax>
					</>
				}
			</div>
		);
		
	}
	
}

Formula.propTypes = {
	text: PropTypes.string,
	showEditor: PropTypes.bool,
	onChange: PropTypes.func,
	config: PropTypes.object,
	disabled: PropTypes.bool,
	allowPaste: PropTypes.any,
};

Formula.defaultProps = {
	allowPaste: 1,
	disabled: false,
	showEditor: false,
	config: {
		enableMenu: true,
		loader: {
			load: [
				"input/asciimath",
				"output/svg",
				// "output/chtml",
			],
		},
		jax: [
			"input/asciimath",
			"output/svg",
		],
		svg: {
			scale: 3,                      // global scaling factor for all expressions
			minScale: .5,                  // smallest scaling factor to use
			mtextInheritFont: false,       // true to make mtext elements use surrounding font
			merrorInheritFont: true,       // true to make merror text use surrounding font
			mathmlSpacing: false,          // true for MathML spacing rules, false for TeX rules
			skipAttributes: {},            // RFDa and other attributes NOT to copy to the output
			exFactor: .5,                  // default size of ex in em units
			displayAlign: 'center',        // default for indentalign when set to 'auto'
			displayIndent: '0',            // default for indentshift when set to 'auto'
			fontCache: 'local',            // or 'global' or 'none'
			localID: null,                 // ID to use for local font cache (for single equation processing)
			internalSpeechTitles: true,    // insert <title> tags with speech content
			titleID: 0                     // initial id number to use for aria-labeledby titles
		},
	},
};