import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import axios from "axios";
import i18next from "i18next";
import Group from "../../models/Group";
import DateHelper from "../../helpers/DateHelper";
import Utils from "../../helpers/Utils";
import User from "../../models/User";
import Tree from "./Tree";
import ModelsPage from "./ModelsPage";

export default class GroupForm extends React.Component {
	
	mode = null;
	
	constructor(props) {
		
		super(props);
		
		let model = props.model ? {...props.model} : new Group();
		
		this.state = {
			model: model,
			loadTeachers: true,
			teachersFound: props.teachers,
			showSelectedTeachersOnly: false,
			showSelectedCoursesOnly: false,
		};
		
		this.mode = model.id ? 'update' : 'create';
		
		if (this.mode == 'create') {
			if (props.deps.length == 1) {
				model.deps_ids.push(props.deps[0].id);
			}
		}
		
		if (!props.model && Config.allowFormFake()) {
			this.state.model.name = i18next.t("Test Group") + ' ' + DateHelper.dateTime();
		}
		
	}
	
	inputChange = (event) => {
		
		const logName = 'GroupForm.inputChange';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		const model = this.state.model;
		Logger.log(model, 'model', logAllow);
		
		const target = event.target;
		Logger.log(target, 'target', logAllow);
		
		let attr = target.getAttribute('id');
		Logger.log(attr, 'attr', logAllow);
		
		const isChbxList = target.hasAttribute('data-chbx-list');
		Logger.log(isChbxList, 'isChbxList', logAllow);
		
		// const value = target.type === 'checkbox' ? target.checked : target.value;
		// Logger.log(value, 'value', logAllow);
		
		if (isChbxList) {
			
			attr = target.getAttribute('name');
			
			const array = model[attr];
			Logger.log(array, 'array (before)', logAllow);
			
			const checked = target.checked;
			Logger.log(checked, 'checked', logAllow);
			
			let value = target.value;
			Logger.log(value, 'value', logAllow);
			
			if (target.getAttribute('data-value-type') === 'int') {
				value = parseInt(value);
			}
			
			const valueIndex = array.indexOf(value);
			Logger.log(valueIndex, 'valueIndex', logAllow);
			
			if (checked && valueIndex < 0) {
				array.push(value);
			} else {
				array.splice(valueIndex, 1);
			}
			
			Logger.log(array, 'array (after)', logAllow);
			
			model[attr] = array;
			
		} else {
			
			const value = target.type === 'checkbox' ? target.checked : target.value;
			
			model[attr] = value;
			
		}
		
		Logger.log(model, 'model', logAllow);
		
		this.setState({
			model: model,
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	submit = (event) => {
		
		event.preventDefault();
		
		const form = event.currentTarget;
		
		if (form.checkValidity()) {
			
			const model = this.state.model;
			
			if (!model.deps_ids || !Array.isArray(model.deps_ids) || (Array.isArray(model.deps_ids) && model.deps_ids.length < 1)) {
				window.alert(i18next.t("Please select departments"));
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: this.props.model ? 'put' : 'post',
				url: Utils.apiUrl('groups') + (this.props.model ? '/' + this.props.model.id : ''),
				data: model,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'GroupForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.afterSubmit) {
					this.props.afterSubmit(response);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		}
		
	};
	
	render() {
		
		const logName = 'GroupForm.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		Logger.log(this.state, 'this.state', logAllow);
		
		const model = this.state.model;
		
		let teachersLoadParams = {
			'filter[role_id]': User.teacherRoleId,
		};
		
		Logger.log(this.state.showSelectedTeachersOnly, 'this.state.showSelectedTeachersOnly', logAllow);
		
		if (this.state.showSelectedTeachersOnly) {
			
			if (model.teachers_ids.length > 0) {
				teachersLoadParams['filter[id][in]'] = model.teachers_ids;
			} else {
				teachersLoadParams['filter[id]'] = -1;
			}
			
		}
		
		Logger.log(teachersLoadParams, 'teachersLoadParams', logAllow);
		
		let coursesLoadParams = {
			'filter[is_active]': 1,
			'filter[department_id][in]': model.deps_ids,
			'sort': 'name',
			// 'sort': 'department.name, course.name',
		};
		
		if (this.state.showSelectedCoursesOnly) {
			if (model.courses_ids.length > 0) {
				coursesLoadParams['filter[id][in]'] = model.courses_ids;
			} else {
				coursesLoadParams['filter[id]'] = -1;
			}
		}
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<form className={'GroupForm'} onSubmit={this.submit}>
				
				<div className="form-group name required">
					<label htmlFor={'name'}>{i18next.t("Name")}</label>
					<input 
						id={'name'}
						type="text"
						className={'form-control'}
						value={model.name}
						onChange={this.inputChange}
						required={true}
					/>
				</div>
				
				<div className="form-group about">
					<label htmlFor={'about'}>{i18next.t("About")}</label>
					<textarea
						id={'about'}
						rows={5}
						className={'form-control'}
						value={model.about}
						onChange={this.inputChange}
					/>
				</div>
				
				{this.props.deps.length > 0 && (
					
					<fieldset className={'departments'}>
						
						<legend>{i18next.t("Departments")}</legend>
						
						<Tree
							data={this.props.deps}
							itemViewer={(dep) => {
								
								const logName = 'GroupForm.depsTree.itemViewer';
								const logAllow = 0;
								const logCollapsed = 0;
								
								Logger.groupStart(logName, logAllow, logCollapsed);
								
								const key = 'deps_ids_' + dep.id;
								
								Logger.log(model.deps_ids, 'model.deps_ids', logAllow);
								Logger.log(dep.id, 'dep.id', logAllow);
								
								const checked = model.deps_ids.indexOf(dep.id.toString()) >= 0;
								Logger.log(checked, 'checked', logAllow);
								
								Logger.groupEnd(logAllow);
								
								return (
									<label htmlFor={key}>
										<input
											key={key}
											id={key}
											name={'deps_ids'}
											type={'checkbox'}
											checked={checked}
											onChange={this.inputChange}
											value={dep.id}
											data-chbx-list={true}
										/> {dep.name} <small className={'text-muted'}>#{dep.id}</small>
									</label>
								);
								
							}}
						/>
						
						{/*{this.props.deps.map((dep , depIndex) => {

							const logName = 'GroupForm.deps.map';
							const logAllow = 1;
							const logCollapsed = 0;

							Logger.groupStart(logName, logAllow, logCollapsed);

							const key = 'deps_ids_' + dep.id;

							Logger.log(model.deps_ids, 'model.deps_ids', logAllow);
							Logger.log(dep.id, 'dep.id', logAllow);

							const checked = model.deps_ids.indexOf(dep.id.toString()) >= 0;
							Logger.log(checked, 'checked', logAllow);

							Logger.groupEnd(logAllow);

							return (
								<div>
									<label htmlFor={key}>
										<input
											key={key}
											id={key}
											name={'deps_ids'}
											type={'checkbox'}
											checked={checked}
											onChange={this.inputChange}
											value={dep.id}
											data-chbx-list={true}
											// data-value-type={'int'}
										/> {dep.name}
									</label>
								</div>
							);
						})}*/}
						
						<hr/>
					
					</fieldset>
				
				)}
				
				<fieldset className={'teachers'}>
					
					<legend>{i18next.t("Teachers")}</legend>
					
					<label htmlFor={'picked-teachers-only'}>
						<input
							id={'picked-teachers-only'}
							type={'checkbox'}
							checked={this.state.showSelectedTeachersOnly}
							onChange={(event) => {
								
								let checked = event.target.checked;
								
								this.setState((prevState) => {
									return {
										showSelectedTeachersOnly: checked,
										// loadTeachers: true,
									}
								});
								
							}}
						/> {i18next.t("Show selected only")}
					</label>
					
					<ModelsPage
						model={User}
						loadUrl={Utils.apiUrl('users')}
						loadParams={teachersLoadParams}
						pagerControlsConfig={'start'}
						pageSize={7}
						preloader={this.props.preloader}
						cssClass={'users-models-page'}
						gridViewCols={{
							id: {
								name: 'ID',
								filter: 'number',
							},
							surname: {
								name: i18next.t("Last name"),
								filter: 'text',
							},
							name: {
								name: i18next.t("First name"),
								filter: 'text',
							},
							patron: {
								name: i18next.t("Middle name"),
								filter: 'text',
							},
							role_id: {
								name: i18next.t("Role"),
								value: (model) => {
									return model.role_name;
								}
							},
							email: {
								name: 'E-mail',
								value: (model) => {
									return (
										<a href={'mailto:' + model.email}
										   onClick={(event) => {
											   event.stopPropagation();
										   }}
										   title={i18next.t("Отправить E-mail")}
										>{model.email}</a>
									);
								},
								filter: 'text',
							},
						}}
						afterLoad={(response, models, page) => {
							this.setState((prevState) => {
								return {
									// users: models,
									// usersPage: page,
									// usersReload: false,
								}
							});
						}}
						rowOnClick={(user, event) => {
							
							let userIndex = model.teachers_ids.indexOf(user.id);
							
							if (userIndex >= 0) {
								model.teachers.splice(userIndex, 1);
								model.teachers_ids.splice(userIndex, 1);
							} else {
								model.teachers.push(user);
								model.teachers_ids.push(user.id);
							}
							
							this.setState((prevState) => {
								return {
									model: model,
								}
							});
							
						}}
						rowClassNameMaker={(user) => {
							return model.teachers_ids.includes(user.id)
								? 'table-success'
								: '';
						}}
						rowTitleMaker={(user) => {
							return i18next.t("Edit");
						}}
						// page={this.state.usersPage}
						// reload={this.state.loadTeachers}
					/>
				
				</fieldset>

				<hr/>
				
				<fieldset className={'course-picker'}>
					
					<legend>{i18next.t("Courses")}</legend>
					
					{(model.deps_ids.length > 0) ? (
						
						<>
							
							<div className={`restricted-course-access-box`}>
								<label htmlFor={'restricted_course_access'}>
									<input
										id={'restricted_course_access'}
										type={'checkbox'}
										checked={model.restricted_course_access == 1}
										onChange={(event) => {
											
											let checked = event.target.checked;
											
											model.restricted_course_access = checked ? 1 : 0
											
											this.setState((prevState) => {
												return {
													model: model,
												}
											});
											
										}}
									/> {i18next.t("Restricted course access")}
								</label>
							</div>
							
							{(model.restricted_course_access == 1) && (
								
								<>
								
									{/* 
									<div className="alert alert-warning">
										<b>{i18next.t("Внимание!")}</b> {i18next.t("После сохранения группы с выбранными курсами, все студенты, включенные в группу, будут иметь доступ ТОЛЬКО к указанным курсам. Индивидуальные настройки доступа к курсам для этих студентов будут сброшены.")}
									</div>
									*/}
								
									<label htmlFor={'selected-courses-only'}>
										<input
											id={'selected-courses-only'}
											type={'checkbox'}
											checked={this.state.showSelectedCoursesOnly}
											onChange={(event) => {
												
												let checked = event.target.checked;
												
												this.setState((prevState) => {
													return {
														showSelectedCoursesOnly: checked,
													}
												});
												
											}}
										/> {i18next.t("Show selected only")}
									</label>
									
									<ModelsPage
										loadUrl={Utils.apiUrl('courses')}
										loadParams={coursesLoadParams}
										preloader={this.props.preloader}
										gridViewCols={{
											id: {
												name: 'ID',
												filter: 'number',
											},
											name: {
												name: i18next.t("Title"),
												filter: 'text',
												filterLike: true,
											},
											department_id: {
												name: i18next.t("Department ID"),
												value: (course) => {
													return [
														course.department_id,
														course.department_name,
													].join(' | ');
												},
												filter: 'number',
											},
										}}
										rowOnClick={(course) => {
											
											const logName = 'GroupForm.coursePicker.rowOnClick';
											const logAllow = 0;
											const logCollapsed = 0;
											
											Logger.groupStart(logName, logAllow, logCollapsed);
											
											Logger.log(model, 'model [before]', logAllow);
											
											if (model) {
												
												let oldCoursesIds = model.courses_ids;
												Logger.log(oldCoursesIds, 'oldCoursesIds', logAllow);
												
												let newCoursesIds = Utils.arrayToggleElement(model.courses_ids, course.id);
												Logger.log(newCoursesIds, 'newCoursesIds', logAllow);
												
												model.courses_ids = newCoursesIds;
												Logger.log(model, 'model [after]', logAllow);
												
												this.setState((prevState) => {
													return {
														model: model,
													}
												});
												
											}
											
											Logger.groupEnd(logAllow);
											
										}}
										rowClassNameMaker={(course) => {
											let out = [];
											let isSelected = model.courses_ids.includes(course.id);
											if (isSelected) {
												out.push('table-success');
											}
											return out.join(' ');
										}}
										// showRealoadBtn={true}
									/>
								
								</>
								
							)}
							
						</>
						
					) : (
						
						<>
							
							<div className={`alert alert-info`}>
								{i18next.t('Please select deparments')}
							</div>
							
						</>
						
					)}
				
				</fieldset>
				
				<hr/>
				
				<div className="controls">
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button type={'button'}
										className={'my-btn my-btn-danger'}
										onClick={(event) => {
											this.props.cancel();
										}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button type={'submit'}
									className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
			
		);
		
	}
	
}

GroupForm.propTypes = {
	model: PropTypes.object,
	afterSubmit: PropTypes.func,
	cancel: PropTypes.func,
	teachers: PropTypes.arrayOf(PropTypes.instanceOf(User)),
	preloader: PropTypes.object,
	deps: PropTypes.arrayOf(PropTypes.object),
};

GroupForm.defaultProps = {
	model: null,
	deps: [],
	teachers: [],
};