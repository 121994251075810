import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import Logger from "../../helpers/Logger";

import User from "../../models/User";

import GroupForm from "./GroupForm";
import Group from "../../models/Group";
import BackBtn from "./BackBtn";
import UserForm from "./UserForm";
import ModelsPage from "./ModelsPage";
import DepartmentForm from "./DepartmentForm";
import Department from "../../models/Department";
import Pager from "./Pager";

import "./StudentsPage.css";
import StudentView from "./StudentView";
import {Link} from "react-router-dom";
import UserImportModal from "./UserImportModal";

export default class StudentsPage extends React.Component {
	
	static debug = 0;
	
	constructor(props) {
		super(props);
		this.state = {
			
			groups: [],
			groupsPageNum: 1,
			groupsPagesCount: 0,
			groupsPageSize: 5,
			groupsDepId: null,
			groupsFilters: {
				like: {},
				equal: {},
			},
			
			currentGroup: null,
			
			groupToEdit: null,
			groupToEditIndex: null,
			groupFormModalIsOpen: false,
			
			students: [],
			currentStudent: null,
			currentStudentIndex: null,
			currentStudentProgress: [],
			currentStudentCourses: [],
			studentToEdit: null,
			studentToEditIndex: null,
			studentFormModalIsOpen: false,
			
			teachers: [],
			teachersFound: [],
			teacherToEdit: null,
			teacherToEditIndex: null,
			teacherFormModalIsOpen: false,
			
			progressUnfoldedCoursesIds: [],
			progressUnfoldedLessonsIds: [],
			progressUnfoldedThemesIds: [],
			
			teachersModalIsOpen: false,
			
			// department
			deps: [],
			depsModalIsOpen: false,
			depToEdit: null,
			depToEditIndex: null,
			
			users: [],
			usersReload: false,
			usersPage: 1,
			usersModalIsOpen: false,
			userToEdit: null,
			usersImportModalIsOpen: 0,
			
			roles: [],
			
		};
	}
	
	groups = {
		
		load: (pageNum = this.state.groupsPageNum, pageSize = this.state.groupsPageSize, filters = this.state.groupsFilters) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			let params = {
				'accessToken': Utils.getUserToken(),
				'sort': 'name',
				// 'filter[is_active]': 1,
				'per-page': pageSize,
				'page': pageNum,
			};
			
			Object.keys(filters.like).forEach((key) => {
				params['filter[' + key + '][like]'] = filters.like[key];
			});
			
			Object.keys(filters.equal).forEach((key) => {
				params['filter[' + key + ']'] = filters.equal[key];
			});
			
			// if (this.state.groupsDepId) {
			//     params['filter[dep_id]'] = this.state.groupsDepId;
			// }
			
			this.setState((prevState) => {
				return {
					groupsPageNum: pageNum,
				}
			});
			
			axios({
				method: 'get',
				url: Utils.apiUrl('groups'),
				data: {},
				params: params,
			}).then((response) => {
				
				const logName = 'StudentsPage2.groups.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let groups = [];
				
				response.data.forEach((groupData) => {
					let group = new Group(groupData);
					groups.push(group);
				});
				
				let pagesCount = parseInt(response.headers['x-pagination-page-count']);
				Logger.log(pagesCount, 'pagesCount', logAllow);
				
				this.setState((prevState) => {
					return {
						groups: groups,
						groupsPagesCount: pagesCount,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					groupFormModalIsOpen: !prevState.groupFormModalIsOpen,
				}
			});
		},
		
		add: () => {
			this.setState((prevState) => {
				return {
					groupToEdit: null,
					groupToEditIndex: null,
					groupFormModalIsOpen: true,
				}
			});
		},
		
		del: (index, permanent = false) => {
			
			let groups = this.state.groups;
			let group = groups[index];
			
			let msg = i18next.t("Archive group '{{name}}'?", {name: group.name});
			
			if (permanent) {
				msg = i18next.t("Delete group '{{name}}' FOREVER?", {name: group.name})
			}
			
			if (!window.confirm(msg)) {
				return;
			}
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: permanent ? 'delete' : 'put',
				url: Utils.apiUrl('groups') + '/' + group.id,
				data: {
					is_active: 0,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.groups.del.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				groups.splice(index, 1);
				
				this.setState((prevState) => {
					return {
						groups: groups,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		afterSubmit: (response) => {
			
			let group = new Group(response.data);
			let groups = this.state.groups;
			
			if (this.state.groupToEdit) {
				
				// update
				groups[this.state.groupToEditIndex] = group;
				
			} else {
				
				// create
				groups.push(group);
				
				this.setState((prevState) => {
					return {
						students: [],
						currentGroup: group,
					}
				});
				
			}
			
			this.setState((prevState) => {
				return {
					groups: groups,
					groupFormModalIsOpen: false,
				}
			});
			
		}
		
	};
	
	// models ops tpl
	students = {
		
		load: (groupId) => {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('users'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'surname',
					'group_id': groupId,
					'filter[role_id]': 5,
					'per-page': 50,
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.students.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let students = [];
				
				response.data.forEach((studentData) => {
					let student = new User(studentData);
					students.push(student);
				});
				
				this.setState((prevState) => {
					return {
						students: students,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Utils.axiosErrorAlert(error);
				
			});
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					studentFormModalIsOpen: !prevState.studentFormModalIsOpen,
					studentToEdit: null,
				}
			});
		},
		
		add: () => {
			this.setState((prevState) => {
				return {
					studentToEdit: null,
					studentFormModalIsOpen: true,
				}
			});
		},
		
		saveSort: (array) => {
			
			const logName = 'StudentsPage2.students.saveSort';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let ids = [];
			
			array.forEach((item) => {
				ids.push(item.id);
			});
			
			axios({
				method: 'post',
				url: Utils.apiUrl('users/save-sort'),
				data: {
					ids: ids,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.students.saveSort.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		moveUp: (currentIndex) => {
			
			let newIndex;
			
			let array = this.state.students;
			
			if (currentIndex > 0) {
				newIndex = currentIndex - 1;
			} else {
				newIndex = array.length - 1;
			}
			
			Utils.arrayMove(array, currentIndex, newIndex);
			
			if (this.state.studentToEditIndex) {
				this.setState((prevState) => {
					return {
						studentToEditIndex: newIndex,
					}
				});
			}
			
			this.students.saveSort(array);
			
		},
		
		moveDown: (currentIndex) => {
			
			let newIndex;
			
			let array = this.state.students;
			
			if (currentIndex === array.length - 1) {
				newIndex = 0;
			} else {
				newIndex = currentIndex + 1;
			}
			
			Utils.arrayMove(array, currentIndex, newIndex);
			
			if (this.state.studentToEditIndex) {
				this.setState((prevState) => {
					return {
						studentToEditIndex: newIndex,
					}
				});
			}
			
			this.students.saveSort(array);
			
		},
		
		del: (index) => {
			
			let students = this.state.students;
			let student = students[index];
			
			if (!window.confirm(i18next.t('Remove student {{student_name}} from group {{group_name}}?', {
				student_name: student.surname + ' ' + student.name,
				group_name: this.state.currentGroup.name,
			}))) {
				return;
			}
			
			axios({
				method: 'put',
				url: Utils.apiUrl('users') + '/' + student.id,
				data: {
					group_id: 14,
				},
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.students.del.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				students.splice(index, 1);
				
				this.setState((prevState) => {
					return {
						students: students,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
		},
		
		afterSubmit: (response) => {
			
			const logName = 'StudentsPage2.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let student = new User(response.data);
			Logger.log(student, 'student', logAllow);
			
			let studentIndex = this.state.studentToEditIndex;
			Logger.log(studentIndex, 'studentIndex', logAllow);
			
			let students = this.state.students;
			
			if (this.state.studentToEdit) {
				
				// update
				
				students[studentIndex] = student;
				
				// скрываем студента, если он перемещён в другую группу
				if (student.groups_ids.indexOf(this.state.currentGroup.id.toString()) < 0) {
					students.splice(studentIndex, 1);
				}
				
				this.setState((prevState) => {
					return {
						students: students,
					}
				});
				
			} else {
				
				// create
				
				students.unshift(student);
				
				this.setState((prevState) => {
					return {
						studentToEdit: student,
						studentToEditIndex: students.length - 1,
					}
				});
				
				student.copyInviteText();
				
			}
			
			this.setState((prevState) => {
				return {
					students: students,
					studentFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	teachers = {
		
		load: () => {
			
			const logName = 'StudentsPage2.teachers.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			axios({
				method: 'get',
				url: Utils.apiUrl('users'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'filter[role_id]': 6,
					'filter[is_active]': 1,
					'sort': 'surname, name',
					'per-page': 999,
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.teachers.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let teachers = response.data.map((teacherData) => {
					return new User(teacherData);
				});
				
				this.setState((prevState) => {
					return {
						teachers: teachers,
						teachersFound: teachers,
					}
				});
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		add: () => {
			
			const logName = 'StudentsPage2.teachers.add';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			this.setState((prevState) => {
				return {
					teacherToEdit: null,
					teacherFormModalIsOpen: true,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		toggleFormModal: () => {
			this.setState((prevState) => {
				return {
					teacherFormModalIsOpen: !prevState.teacherFormModalIsOpen,
				}
			});
		},
		
		toggleManageModal: () => {
			this.setState((prevState) => {
				return {
					teachersModalIsOpen: !prevState.teachersModalIsOpen,
				}
			});
		},
		
		afterSubmit: (response) => {
			
			const logName = 'StudentsPage2.teachers.afterSubmit';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			Logger.log(response, 'response', logAllow);
			
			let teacher = new User(response.data);
			Logger.log(teacher, 'teacher', logAllow);
			
			let teachers = this.state.teachers;
			let teachersFound = this.state.teachersFound;
			
			let teacherIndex = teachers.findIndex(x => x.id == teacher.id);
			let teacherFoundIndex = teachersFound.findIndex(x => x.id == teacher.id);
			
			if (this.state.teacherToEdit) {
				
				// update
				teachers[teacherIndex] = teacher;
				teachersFound[teacherFoundIndex] = teacher;
				
			} else {
				
				// create
				teachers.push(teacher);
				
			}
			
			this.setState((prevState) => {
				return {
					teachers: teachers,
					teacherFormModalIsOpen: false,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
	};
	
	deps = {
		
		load: () => {
			
			const logName = 'StudentsPage2.deps.load';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			axios({
				method: 'get',
				url: Utils.apiUrl('department'),
				data: {},
				params: {
					'accessToken': Utils.getUserToken(),
					'sort': 'name',
					'filter[is_active]': 1,
				},
			}).then((response) => {
				
				const logName = 'StudentsPage2.deps.load.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				let deps = response.data;
				// let deps = response.data.map(depData => new Department(depData));
				Logger.log(deps, 'deps', logAllow);
				
				this.setState((prevState) => {
					return {
						deps: deps,
					}
				});
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				Utils.axiosErrorAlert(error);
			});
			
			Logger.groupEnd(logAllow);
			
		},
		
		toggleModal: () => {
			this.setState((prevState) => {
				return {
					depsModalIsOpen: !prevState.depsModalIsOpen,
					depToEdit: null,
					depToEditIndex: null,
				}
			});
		},
		
		renderTree: (dep, depIndex) => {
			
			let user = this.props.user;
			
			return (
				<li>
					{(dep.parent_id || user.can('manageTopLevelDepartments')) ? (
						<a
							href="#"
							onClick={(event) => {
								event.preventDefault();
								this.setState((prevState) => {
									return {
										depToEdit: dep,
										depToEditIndex: depIndex,
									}
								});
							}}
						>{dep.name}</a>
					) : (
						<span>{dep.name}</span>
					)}
					{(dep.children.length > 0) &&
						<ul>
							{dep.children.map((subDep, subDepIndex) => {
								return this.deps.renderTree(subDep, subDepIndex);
							})}
						</ul>
					}
				</li>
			);
		}
		
	};
	
	users = {
		
		toggleModal: () => {
			this.setState((prevState) => {
				return {
					usersModalIsOpen: !prevState.usersModalIsOpen,
					userToEdit: null,
				}
			});
		},
		
		toggleImportModal: () => {
			this.setState((prevState) => {
				return {
					usersImportModalIsOpen: !prevState.usersImportModalIsOpen,
				}
			});
		},
		
	};
	
	componentDidMount() {
		this.groups.load();
		this.teachers.load();
		this.deps.load();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		// if (this.state.currentStudent && prevState.currentStudentProgress !== this.state.currentStudentProgress) {
		//     this.students.loadProgress(this.state.currentStudent.id);
		// }
		
		// if (this.state.groupsFilters != prevState.groupsFilters) {
		//     this.groups.load();
		// }
		
		// if (this.state.groupsDepId != prevState.groupsDepId) {
		//     this.groups.load();
		// }
		
	}
	
	render() {
		
		const logName = 'StudentsPage.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.state, 'this.state', logAllow);
		Logger.log(this.props, 'this.props', logAllow);
		
		const user = this.props.user;
		const userIsOwner = user.is('owner');
		
		let currentStudent = this.state.currentStudent;
		let currentStudentGroup = null;
		if (currentStudent) {
			currentStudentGroup = this.state.groups.filter(group => group.id == currentStudent.group_id)[0];
		}
		
		let userFormBackBtn =
			<BackBtn
				onClick={() => {
					this.setState((prevState) => {
						return {
							userToEdit: null,
						}
					});
				}}
				title={i18next.t("Back to the users list")}
			/>;
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'StudentsPage'}>
				
				{currentStudent ? (
					
					<StudentView
						student={currentStudent}
						user={this.props.user}
						preloader={this.props.preloader}
						backBtnOnClick={() => {
							this.setState((prevState) => {
								return {
									currentStudent: null,
								}
							});
						}}
					/>
				
				) : (
					
					<div className={'students-manager content-root row'}>
						
						<section className="students-col col-xl-8 col-lg-7 col-md-6">
							
							<div className="h2">
								{this.state.currentGroup ? (
									<span className={'students-col-header'}>{i18next.t("Students")} / {this.state.currentGroup.name}</span>
								) : (
									<span className={'students-col-header'}>{i18next.t("Students")}</span>
								)}
							</div>
							
							<div className="list students-list">
								
								{this.props.user.can('manageStudents') ? (
									<div
										className={'list-item list-item-active add-student-btn'}
										onClick={this.students.add}
									>+ {i18next.t("Add student")}</div>
								) : (
									<>
										{!this.state.currentGroup && (
											<div className="list-item">
												{i18next.t("Select group")}
											</div>
										)}
									</>
								)}
								
								{this.state.currentGroup && this.state.students.length < 1 && (
									<div className={'list-item'}>
										{i18next.t("Students not found")}
									</div>
								)}
								
								{/*{this.state.currentGroup && this.props.user.is(['owner', 'admin']) ? (
                                    <div className="list-item list-item-active"
                                         onClick={this.students.add}
                                    >+ {i18next.t("Add student")}</div>
                                ) : (
                                    <div className="list-item">
                                        {i18next.t("Select group")}
                                    </div>
                                )}*/}
								
								{this.state.students.map((student, studentIndex) => {
									
									return (
										
										<div
											className={[
												'list-item',
												'list-item-active',
												'students-list-item',
												'student-preview',
											].join(' ')}
											onClick={(event) => {
												this.setState((prevState) => {
													return {
														currentStudent: student,
														currentStudentIndex: studentIndex,
														currentStudentProgress: [],
													}
												});
												// this.students.loadProgress(student.id);
												// this.students.loadCourses(student.id);
											}}
										>
											
											<div className="info">
												
												<div className="name">
													
													{studentIndex + 1}. {student.getDisplayName()}
													
													{this.props.user.can('debugInfo') && (
														<div
															className={[
																'debug-info',
																'd-inline-block',
																'ml-1',
															].join(' ')}
														>
															#{student.id} {/*&bull; {student.email} &bull; {student.invite_password}*/}
														</div>
													)}
													
													{(student.latest_activity_datetime) && (
														<div className={`latest-activity-datetime text-muted`}>
															<small title={i18next.t("Latest activity date & time")}>
																{moment(student.latest_activity_datetime).format('DD.MM.YYYY HH:mm')}
															</small>
														</div>
													)}
													
												</div>
												
												{/*<div className="email">
													<small><a href={'mailto:' + currentStudent.email}>{currentStudent.email}</a></small>
												</div>*/}
												
											</div>
											
											{this.props.user.can(['manageStudents']) && (
												
												<>
													
													{/* кнопка "меню" в виде многоточия справа */}
													{/*<Dropdown>

                                                        <Dropdown.Toggle
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        ></Dropdown.Toggle>

                                                        <Dropdown.Menu>

                                                            <Dropdown.Item
                                                                onClick={(event) => {
                                                                    this.setState((prevState) => {
                                                                        return {
                                                                            studentToEdit: student,
                                                                            studentToEditIndex: studentIndex,
                                                                            studentFormModalIsOpen: true,
                                                                        }
                                                                    });
                                                                }}
                                                            >{i18next.t("Edit")}</Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={(event) => {
                                                                    this.students.del(studentIndex);
                                                                }}
                                                            >{i18next.t("Hide")}</Dropdown.Item>

                                                        </Dropdown.Menu>

                                                    </Dropdown>*/}
													
													<div className="controls bottom-controls">
														
														<button
															type={'button'}
															className={[
																'edit-btn',
																'my-btn',
																'my-btn-sm',
															].join(' ')}
															onClick={(event) => {
																event.stopPropagation();
																this.setState((prevState) => {
																	return {
																		studentToEdit: student,
																		studentToEditIndex: studentIndex,
																		studentFormModalIsOpen: true,
																	}
																});
															}}
															title={i18next.t("Edit")}
														><Icon.Pencil/></button>
														
														<button
															type={'button'}
															className={[
																'edit-btn',
																'my-btn',
																'my-btn-sm',
																'new-password-btn',
															].join(' ')}
															onClick={(event) => {
																
																event.stopPropagation();
																
																if (!window.confirm("Уверены?")) {
																	return;
																}
																
																if (this.props.preloader) {
																	this.props.preloader.show();
																}
																
																axios({
																	method: 'post',
																	url: Utils.apiUrl('users/student-new-password'),
																	data: {
																		id: student.id,
																	},
																	params: {
																		'accessToken': Utils.getUserToken(),
																	},
																}).then((response) => {
																	
																	const logName = 'StudentsPage2.newPassword.ajax.done';
																	const logAllow = 1;
																	const logCollapsed = 0;
																	
																	Logger.groupStart(logName, logAllow, logCollapsed);
																	
																	Logger.log(response, 'response', logAllow);
																	
																	let student = new User(response.data);
																	Logger.log(student, 'student', logAllow);
																	
																	let students = this.state.students;
																	students[studentIndex] = student;
																	
																	this.setState((prevState) => {
																		return {
																			students: students,
																		}
																	});
																	
																	if (this.props.preloader) {
																		this.props.preloader.hide();
																	}
																	
																	student.copyInviteText();
																	
																	Logger.groupEnd(logAllow);
																	
																}).catch((error) => {
																	
																	if (this.props.preloader) {
																		this.props.preloader.hide();
																	}
																	
																	Utils.axiosErrorAlert(error);
																	
																});
																
															}}
															// title={i18next.t("Edit")}
														>{i18next.t("Reset password")}</button>
														
														{(student.invite_password) &&
															<button
																type={'button'}
																className={[
																	'edit-btn',
																	'my-btn',
																	'my-btn-sm',
																	'new-password-btn',
																].join(' ')}
																onClick={(event) => {
																	event.stopPropagation();
																	student.copyInviteText();
																}}
																// title={i18next.t("Edit")}
															>{i18next.t("Copy invite text")}</button>
														}
														
														<button
															type={'button'}
															className={[
																'show-student-page-btn',
																'my-btn',
																'my-btn-sm',
															].join(' ')}
															onClick={(event) => {
																event.stopPropagation();
															}}
															title={i18next.t("Open on a separate page")}
														>
															<Link
																to={'/student/' + student.id}
																target={'_blank'}
															><Icon.ArrowUpRightCircleFill/></Link>
														</button>
														
														{/*<button
                                                            type={'button'}
                                                            className={[
                                                                'del-btn',
                                                                'my-btn',
                                                                'my-btn-sm',
                                                            ].join(' ')}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                this.students.del(studentIndex);
                                                            }}
                                                            title={i18next.t("Hide")}
                                                        ><Icon.Trash/></button>*/}
													
													</div>
												
												</>
											
											)}
										
										</div>
									
									);
									
								})}
							
							</div>
						
						</section>
						
						<section className="groups-col col-xl-4 col-lg-5 col-md-6">
							
							<div className="h2">{i18next.t("Groups")}</div>
							
							<div className="list groups-list">
								
								{this.props.user.can('manageGroups') && (
									
									<div className="controls top-controls">
										
										<button
											type={'button'}
											className={[
												'add-group-btn',
												'my-btn',
												// 'my-btn-sm',
												// 'my-btn-wide',
											].join(' ')}
											onClick={(event) => {
												this.groups.add();
											}}
											title={i18next.t("Add group")}
										>+ {i18next.t("Group")}</button>
										
										{this.props.user.can('manageUsers') && (
											
											<>
												
												<button
													type={'button'}
													className={[
														'users-import-btn',
														'my-btn',
														// 'my-btn-sm',
														// 'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.users.toggleImportModal()
													}}
												>+ {i18next.t("Import")}</button>
												
												<button
													type={'button'}
													className={[
														'users-modal-btn',
														'my-btn',
														// 'my-btn-sm',
														// 'my-btn-wide',
													].join(' ')}
													onClick={(event) => {
														this.setState((prevState) => {
															return {
																usersModalIsOpen: true,
															}
														});
													}}
												>{i18next.t("Users")}</button>
											
											</>
										
										)}
										
										{this.props.user.can('manageDepartments') && (
											
											<button
												type={'button'}
												className={[
													'deps-manager-btn',
													'my-btn',
													// 'my-btn-sm',
													// 'my-btn-wide',
												].join(' ')}
												onClick={(event) => {
													this.setState((prevState) => {
														return {
															depsModalIsOpen: true,
														}
													});
												}}
											>{i18next.t("Departments")}</button>
										
										)}
									
									</div>
								
								)}
								
								<div className="body">
									
									{/* search groups by name & id */}
									<div className="group-search-box">
										
										<form onSubmit={(event) => {
											event.preventDefault();
											this.groups.load(1);
										}}>
											<div className="row">
												
												<div className="col-xl-12">
													<div className={'form-group department-search dep-search department_id'}>
														<label htmlFor={'parent_id'}>
															{i18next.t("Department")}
														</label>
														<select
															id={'parent_id'}
															name={'parent_id'}
															className={'form-control'}
															value={this.state.groupsDepId}
															onChange={(event) => {
																
																let filters = this.state.groupsFilters;
																filters['equal']['dep_id'] = event.target.value;
																
																this.setState((prevState) => {
																	return {
																		groupsPageNum: 1,
																		groupsFilters: filters,
																	}
																});
																
																this.groups.load(1, this.state.groupsPageSize, filters);
																
															}}
														>
															<option value={''}>{i18next.t("All departments")}</option>
															{Department.flatList(this.state.deps).map((dep) => {
																
																let prefix = [];
																for (let i = 0; i < dep.level; i++) {
																	prefix.push('+');
																}
																prefix = prefix.join(' ');
																
																return (
																	<option value={dep.id}>
																		{prefix} {dep.name}
																	</option>
																);
																
															})}
														</select>
													</div>
												</div>
												
												<div className="col-xl-12">
													<div className="input-group group-id-search">
														<div className="input-group-prepend">
															<button
																className={'btn btn-outline-secondary group-id-search_clear-btn'}
																type={'button'}
																onClick={() => {
																	let filters = this.state.groupsFilters;
																	delete filters['equal']['id'];
																	// filters['equal']['id'] = '';
																	this.setState((prevState) => {
																		return {
																			groupsPageNum: 1,
																			groupsFilters: filters,
																		}
																	});
																	this.groups.load(1, this.state.groupsPageSize, filters);
																}}
															><Icon.X/></button>
														</div>
														<input
															type={'number'}
															className={'form-control'}
															value={this.state.groupsFilters['equal']['id'] ? this.state.groupsFilters['equal']['id'] : ''}
															placeholder={i18next.t("Group ID")}
															// placeholder={i18next.t("Поиск групп по названию")}
															onChange={(event) => {
																
																const logName = 'StudentsPage2.groupNameSearch.onChange';
																const logAllow = 1;
																const logCollapsed = 0;
																
																Logger.groupStart(logName, logAllow, logCollapsed);
																
																let id = event.target.value;
																
																let filters = this.state.groupsFilters;
																filters['equal']['id'] = id;
																
																this.setState((prevState) => {
																	return {
																		groupsPageNum: 1,
																		groupsFilters: filters,
																	}
																});
																
																// this.groups.load(0, this.state.groupsPageSize, filters);
																
																Logger.groupEnd(logAllow);
																
															}}
														/>
														<div className="input-group-append">
															<button
																className="btn btn-outline-secondary"
																type="submit"
																id="button-addon2"
																onClick={() => {
																	// this.groups.load(0);
																}}
															><Icon.Search/></button>
														</div>
													</div>
												</div>
												
												<div className="col-xl-12">
													<div className="input-group group-name-search">
														<div className="input-group-prepend">
															<button
																className={'btn btn-outline-secondary group-id-search_clear-btn'}
																type={'button'}
																onClick={() => {
																	let filters = this.state.groupsFilters;
																	filters['like']['name'] = '';
																	this.setState((prevState) => {
																		return {
																			groupsPageNum: 1,
																			groupsFilters: filters,
																		}
																	});
																	this.groups.load(1, this.state.groupsPageSize, filters);
																}}
															><Icon.X/></button>
														</div>
														<input
															type="text"
															className={'form-control'}
															value={this.state.groupsFilters['like']['name']}
															placeholder={i18next.t("Group name or it's part")}
															// placeholder={i18next.t("Поиск групп по названию")}
															onChange={(event) => {
																
																const logName = 'StudentsPage2.groupNameSearch.onChange';
																const logAllow = 1;
																const logCollapsed = 0;
																
																Logger.groupStart(logName, logAllow, logCollapsed);
																
																let groupName = event.target.value;
																
																let filters = this.state.groupsFilters;
																filters['like']['name'] = groupName;
																
																this.setState((prevState) => {
																	return {
																		groupsPageNum: 1,
																		groupsFilters: filters,
																	}
																});
																
																// this.groups.load(0, this.state.groupsPageSize, filters);
																
																Logger.groupEnd(logAllow);
																
															}}
														/>
														<div className="input-group-append">
															<button
																className="btn btn-outline-secondary"
																type="submit"
																id="button-addon2"
																onClick={() => {
																	// this.groups.load(0);
																}}
															><Icon.Search/></button>
														</div>
													</div>
												</div>
											
											</div>
										
										</form>
									
									</div>
									
									<br/>
									
									{(this.state.groups.length < 1) && (
										<div className={'empty-msg'}>
											{i18next.t("Groups not found")}
										</div>
									)}
									
									{(this.state.groupsPagesCount > 1) &&
										<>
											<Pager
												pagesCount={this.state.groupsPagesCount}
												currentPageIndex={this.state.groupsPageNum - 1}
												controlsConfig={"start"}
												pageClick={(pageIndex) => {
													
													const logName = 'StudentsPage2.groupsPager.pageClick';
													const logAllow = 1;
													const logCollapsed = 0;
													
													Logger.groupStart(logName, logAllow, logCollapsed);
													
													Logger.log(pageIndex, 'pageIndex', logAllow);
													
													this.groups.load(pageIndex + 1);
													
													Logger.groupEnd(logAllow);
													
												}}
											/>
											<br/>
										</>
									}
									
									{this.state.groups.map((group, groupIndex) => {
										
										let depsNames = group.deps.map(dep => dep.name);
										
										return (
											<div className={[
												'group',
												'group-list-item',
												'list-item',
												'list-item-active',
												this.state.currentGroup && group.id == this.state.currentGroup.id ? 'list-item-current' : '',
											].join(' ')}
												 key={'currentStudentGroup-' + group.id}
												 onClick={(event) => {
													 
													 this.setState((prevState) => {
														 return {
															 students: [],
															 currentGroup: group,
														 }
													 });
													 
													 this.students.load(group.id);
													 
												 }}
											>
												
												<div className="info">
													
													<span className="name">{group.name}</span>
													
													{this.props.user.can('debugInfo') && (
														<span className={'debug-info ml-1'}>#{group.id}</span>
													)}
													
													{depsNames.length > 0 && (
														<div>
															<small className="dep-name text-muted">
																{depsNames.join(' | ')}
															</small>
														</div>
													)}
													
													{/*{depsNames.length > 0 && (
														<ul>
															{depsNames.map((depName) => {
																return (
																	<li><small className={'text-muted'}>{depName}</small></li>
																);
															})}
														</ul>
													)}*/}
												
												</div>
												
												{this.props.user.can('manageGroups') && (
													
													<div className="controls bottom-controls group-bottom-controls">
														
														<button
															type={'button'}
															className={'my-btn my-btn-sm'}
															onClick={(event) => {
																
																event.stopPropagation();
																
																this.setState((prevState) => {
																	return {
																		groupToEdit: group,
																		groupToEditIndex: groupIndex,
																		groupFormModalIsOpen: true,
																	}
																});
																
															}}
															title={i18next.t("Edit")}
														><Icon.Pencil/></button>
														
														<button
															type={'button'}
															className={'my-btn my-btn-sm'}
															onClick={(event) => {
																event.stopPropagation();
																this.groups.del(groupIndex, false);
															}}
															title={i18next.t("Hide")}
														><Icon.Trash/></button>
														
														{this.props.user.can('delGroups') && (
															<button type={'button'}
																	className={'my-btn my-btn-sm'}
																	onClick={(event) => {
																		event.stopPropagation();
																		this.groups.del(groupIndex, true);
																	}}
																	title={i18next.t("Delete FOREVER")}
															><Icon.FileEarmarkXFill/></button>
														)}
													
													</div>
												
												)}
											
											</div>
										);
									})}
								
								</div>
							
							</div>
						
						</section>
					
					</div>
				
				)}
				
				<div className="modals">
					
					<Modal
						className={'group-form-modal wide-modal'}
						show={this.state.groupFormModalIsOpen}
						onHide={this.groups.toggleFormModal}
						size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.groupToEdit ? (
									<div>{i18next.t("Edit group #{{id}}", {id: this.state.groupToEdit.id})}</div>
								) : (
									<div>{i18next.t("New group")}</div>
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							<GroupForm
								model={this.state.groupToEdit}
								afterSubmit={this.groups.afterSubmit}
								teachers={this.state.teachers}
								preloader={this.props.preloader}
								deps={this.state.deps}
								cancel={() => {
									this.setState((prevState) => {
										return {
											groupFormModalIsOpen: false,
										}
									});
								}}
							/>
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={'student-form-modal'}
						show={this.state.studentFormModalIsOpen}
						onHide={this.students.toggleFormModal}
						size={'lg'}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{this.state.studentToEdit ? (
									<div>
										{i18next.t("Edit student #{{id}}", {
											id: this.state.studentToEdit.id
										})}
									</div>
								) : (
									<div>
										{i18next.t("Add student")}
									</div>
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							{/*<StudentForm
								model={this.state.studentToEdit}
								afterSubmit={this.students.afterSubmit}
								cancel={this.students.toggleFormModal}
								group_id={this.state.currentGroup.id}
								groups={this.state.groups}
								preloader={this.props.preloader}
								alert={this.props.alert}
							/>*/}
							<UserForm
								model={this.state.studentToEdit}
								afterSubmit={this.students.afterSubmit}
								cancel={this.students.toggleFormModal}
								// groups={this.state.groups}
								preloader={this.props.preloader}
								role_id={Config.studentRoleId}
								groups_ids={this.state.currentGroup && this.state.currentGroup.id ? [this.state.currentGroup.id] : null}
								// deps={this.state.deps}
								roles={[
									{
										id: User.studentRoleId,
										name: i18next.t("Student")
									}
								]}
								user={this.props.user}
							/>
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={[
							'users-modal',
							'wide-modal',
						].join(' ')}
						show={this.state.usersModalIsOpen}
						onHide={this.users.toggleModal}
						size={'lg'}
						backdrop={'static'}
						keyboard={false}
					>
						
						<Modal.Header closeButton>
							<Modal.Title>
								{i18next.t("Users")}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body data-has-user-to-edit={this.state.userToEdit ? 1 : 0}>
							
							{!this.state.userToEdit && (
								
								<div className="controls top-controls mb-3">
									
									<button
										type={'button'}
										className={[
											'add-user-btn',
											'my-btn',
											// 'my-btn-sm',
											// 'my-btn-wide',
										].join(' ')}
										onClick={(event) => {
											this.setState((prevState) => {
												return {
													userToEdit: new User(),
												}
											});
										}}
									>+ {i18next.t("User")}</button>
								
								</div>
							
							)}
							
							{this.state.userToEdit && (
								
								<div className={'list-item user-form-box'}>
									
									{this.state.userToEdit.id ? (
										<div className={'h4 mt-2'}>
											{userFormBackBtn} {i18next.t("Edit user #{{id}}", {id: this.state.userToEdit.id})}
										</div>
									) : (
										<div className={'h5 mt-2'}>
											{userFormBackBtn} {i18next.t("Add user")}
										</div>
									)}
									
									<hr/>
									
									<UserForm
										model={this.state.userToEdit}
										afterSubmit={(response, mode) => {
											
											let submitedUser = new User(response.data)
											
											if (mode == 'create') {
												submitedUser.copyInviteText()
											}
											
											this.setState((prevState) => {
												return {
													userToEdit: null,
													usersReload: true,
												}
											});
											
										}}
										cancel={() => {
											this.setState((prevState) => {
												return {
													userToEdit: null,
												}
											});
										}}
										groups={this.state.groups}
										preloader={this.props.preloader}
										deps={this.state.deps}
										roles={this.props.user.available_roles}
										user={this.props.user}
									/>
								</div>
							
							)}
							
							<ModelsPage
								model={User}
								loadUrl={Utils.apiUrl('users')}
								pagerControlsConfig={'start'}
								pageSize={7}
								preloader={this.props.preloader}
								cssClass={'users-models-page'}
								gridViewCols={{
									id: {
										name: 'ID',
										filter: 'number',
										// filterAlias: 'user.id',
										value: (user) => {
											return (
												<Link
													to={'/student/' + user.id}
													target={'_blank'}
													title={i18next.t("Open on a separate page")}
													onClick={(event) => {
														event.stopPropagation();
													}}
												>{user.id} <small><Icon.ArrowUpRightCircleFill/></small></Link>
											);
										},
									},
									surname: {
										name: i18next.t("Last name"),
										filter: 'text',
									},
									name: {
										name: i18next.t("First name"),
										filter: 'text',
									},
									patron: {
										name: i18next.t("Middle name"),
										filter: 'text',
										// filterAlias: 'patron',
										// type: '',
										// value: (model) => {},
									},
									role_id: {
										name: i18next.t("Role"),
										filter: this.props.user.getAvailableRolesListData(),
										value: (model) => {
											return model.role_name;
										}
									},
									email: {
										name: 'E-mail',
										value: (model) => {
											return (
												<a href={'mailto:' + model.email}
												   onClick={(event) => {
													   event.stopPropagation();
												   }}
												   title={i18next.t("Отправить E-mail")}
												>{model.email}</a>
											);
										},
										filter: 'text',
									},
									created_at: {
										name: i18next.t("Created at"),
										filter: 'date',
										filterLike: true,
										width: 250,
										// style: {
										// 	width: '250px',
										// },
									},
									username: {
										name: i18next.t("Login"),
										filter: 'text',
										filterLike: true,
										visible: userIsOwner,
										width: 250,
										// style: {
										// 	width: '250px',
										// },
									},
									// status: {
									// 	name: i18next.t("Status"),
									// 	filter: number,
									// 	visible: userIsOwner,
									// },
								}}
								afterLoad={(response, models, page) => {
									this.setState((prevState) => {
										return {
											// users: models,
											// usersPage: page,
											usersReload: false,
										}
									});
								}}
								rowOnClick={(user, event) => {
									// window.alert('ok');
									this.setState((prevState) => {
										return {
											userToEdit: user,
										}
									});
								}}
								rowTitleMaker={(user) => {
									return i18next.t("Edit");
								}}
								// page={this.state.usersPage}
								reload={this.state.usersReload}
							/>
						
						</Modal.Body>
					
					</Modal>
					
					<Modal
						className={[
							'deps-modal',
							'departments-modal',
						].join(' ')}
						show={this.state.depsModalIsOpen}
						onHide={this.deps.toggleModal}
						size={'lg'}
						backdrop={'static'}
						keyboard={false}
					>
						
						<Modal.Header closeButton={this.state.depToEdit === null}>
							<Modal.Title>
								{this.state.depToEdit ? (
									<>
										{/*<BackBtn
                                            onClick={() => {
                                                this.setState((prevState) => {
                                                    return {
                                                        depToEdit: null,
                                                    }
                                                });
                                            }}
                                        />&nbsp;*/}
										{this.state.depToEdit.id ? (
											i18next.t("Edit department #{{id}}", {id: this.state.depToEdit.id})
										) : (
											i18next.t("Add department")
										)}
									</>
								) : (
									i18next.t("Departments")
								)}
							</Modal.Title>
						</Modal.Header>
						
						<Modal.Body>
							{this.state.depToEdit ? (
								<DepartmentForm
									model={this.state.depToEdit}
									cancel={() => {
										this.setState((prevState) => {
											return {
												depToEdit: null,
											}
										});
									}}
									afterSubmit={(response) => {
										
										this.deps.load();
										
										this.setState((prevState) => {
											return {
												depToEdit: null,
											}
										});
										
									}}
									preloader={this.props.preloader}
									parentDeps={this.state.deps}
									user={this.props.user}
								/>
							) : (
								<>
									<div className="controls mb-2">
										<button
											type={'button'}
											className={[
												'add-dep-btn',
												// 'btn',
												// 'btn-primary',
												'my-btn',
												// 'my-btn-default',
											].join(' ')}
											onClick={(event) => {
												this.setState((prevState) => {
													return {
														depToEdit: new Department(),
													}
												});
											}}
										>+ {i18next.t("Department")}</button>
									</div>
									<ul className={'mb-0'}>
										{this.state.deps.map((dep, depIndex) => {
											return this.deps.renderTree(dep, depIndex);
										})}
									</ul>
								</>
							)}
						</Modal.Body>
					
					</Modal>
					
					<UserImportModal
						isOpen={this.state.usersImportModalIsOpen}
						onHide={() => {
							this.users.toggleImportModal()
						}}
						preloader={this.props.preloader}
					/>
				
				</div>
			
			</div>
		
		);
		
	}
	
}

StudentsPage.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	preloader: PropTypes.object,
	alert: PropTypes.object,
};

StudentsPage.defaultProps = {};